import React from "react"
import { Link } from "gatsby"

import Layout from "/src/components/layout"
import Biogeo from "/src/components/img/biogeo"
import ImgCon from "/src/components/img-con"
import BtnWrap from "/src/components/btn-wrap"
import SEO from "/src/components/seo"

export default ({ data }) => (
  <Layout>
    <SEO title="SAR: Vztah diverzity a plochy" />
    <h1>SAR: Vztah diverzity a plochy</h1>
    <p><strong>SAR</strong> (zkratka z anglick&eacute;ho <em>Species-area relationship</em>) je v ekologii a biogeografii vztah druhov&eacute; <Link to="/biogeografie/biodiverzita/">biodiverzity</Link> a velikosti plochy. V tomto vztahu, bez ohledu na taxonomickou skupinu či typ ekosyst&eacute;mu, počet druhů stoup&aacute; s velikost&iacute; plochy, na kter&eacute; tyto druhy poč&iacute;t&aacute;me.</p>
<hr />
<h2>SAR jako matematick&aacute; funkce</h2>
<p>O. Arrhenius roku 1921 objevil, že SAR z&aacute;vislost nen&iacute; line&aacute;rn&iacute; a l&eacute;pe ji vyjadřuje mocninn&aacute; funkce. Tato funkce m&aacute; v z&aacute;kladu podobu křivky, kter&aacute; nejprve strmě stoup&aacute; a pot&eacute; se zplo&scaron;ťuje.</p>
<p>Matematicky lze vztah druhov&eacute; diverzity a velikosti plochy vyj&aacute;dřit t&iacute;mto vzorcem:</p>
<p className="od-sm">S = cA^z<br /><br />S ... počet druhů<br />c ... konstanta<br />A ... velikost plochy<br />z ... sklon př&iacute;mky (nejčastěji 0.12-0.35)</p>
<p>Pokud je SAR vynesen na logaritmick&yacute;ch os&aacute;ch, lze jej proložit př&iacute;mkou, kterou je možn&eacute; matematicky vyj&aacute;dřit n&aacute;sledovně:</p>
<p className="od-sm">log(S) = log(cA^z) = log(c) + z log(A)</p>
<p>Někdy se tato z&aacute;vislost vyjadřuje př&iacute;mo v semi-logaritmick&eacute;m prostoru:</p>
<p className="od-sm">S = log(cA^z) = log(c) + z log(A)</p>
<h3>Uk&aacute;zkov&yacute; př&iacute;klad v&yacute;počtu diverzity</h3>
<p>Uk&aacute;zkov&yacute; př&iacute;klad v&yacute;počtu na diverzitu využ&iacute;v&aacute; vzorce matematicky vyj&aacute;dřen&eacute;ho SAR a zn&iacute;: "Jak se změn&iacute; počet druhů při z&aacute;niku 80&nbsp;% plochy biotopu při&nbsp;hodnotě&nbsp;z&nbsp;=&nbsp;0.13?"</p>
<p className="od-sm">1) <strong>S = cA^z</strong> (prim&aacute;rn&iacute; vzorec SAR, dosad&iacute;m hodnotu z)<br /><br />2) <strong>S = cA^0.13</strong> (poč&iacute;t&aacute;m pro A, kter&eacute; se zmen&scaron;ilo o 80&nbsp;%, tedy 0.2A)<br /><br />3) <strong>S(0.2A) = c(0.2A)^0.13</strong> (uprav&iacute;m, odděl&iacute;m 0.2 od A)<br /><br />4) <strong>S(0.2A) = 0.2^0.13 * cA^0.13</strong> (podtržen&aacute; č&aacute;st je vzorcem pro S, viz krok&nbsp;2)<br /><br />5) <strong>S(0.2A) = 0.2^0.13 * S</strong> (uprav&iacute;m)<br /><br />6) <strong>S(0.2A) = 0.81 * S</strong> (v&yacute;sledn&yacute; vzorec, S je původn&iacute; počet druhů)</p>
<h3>Trojf&aacute;zov&aacute; křivka SAR</h3>
<p>Sklon př&iacute;mky, tedy parametr z, se měn&iacute; spolu s prostorou &scaron;k&aacute;lou. Křivka SAR je tak<strong> trojf&aacute;zov&aacute;</strong>. V mal&yacute;ch are&aacute;l m&aacute; siln&yacute; sklon (z = 0.43), region&aacute;ln&iacute; měř&iacute;tko je pak charakterizov&aacute;no sklonem slab&scaron;&iacute;m (z = 0.11) a&nbsp;v&nbsp;interprovinčn&iacute;m, kontinent&aacute;ln&iacute;m měř&iacute;tku je opět strm&yacute; (z = 0.68).</p>
<p>Na nejmen&scaron;&iacute;ch ploch&aacute;ch je totiž zastoupeno jen m&aacute;lo druhů, při zvět&scaron;ov&aacute;n&iacute; plochy pak rychle přib&yacute;vaj&iacute; nov&eacute; a z je tak vysok&eacute;.</p>
<p>Při přesunu do men&scaron;&iacute;ch měř&iacute;tek kles&aacute; množstv&iacute; nově zaznamenan&yacute;ch druhů, hojn&eacute; druhy se opakuj&iacute; a z je tak mal&eacute;.</p>
<p>V kontinent&aacute;ln&iacute;ch &scaron;k&aacute;l&aacute;ch pak ale sledovan&aacute; plocha překračuje are&aacute;ly dř&iacute;ve zaznamenan&yacute;ch druhů a jsou tak zaznamen&aacute;van&yacute; zcela jin&eacute; druhy z odli&scaron;n&yacute;ch provinci&iacute;, z je tak opět vysok&eacute;.</p>
<hr />
<h2>IAR: z&aacute;vislost počtu jedinců na plo&scaron;e</h2>
<p><strong>IAR</strong> (zkratka z anglick&eacute;ho <em>Individual-area relationship</em>) je vztah mezi počtem jedinců a velikost&iacute; plochy. Pokud jsou are&aacute;ly vnořen&eacute;, jeden je souč&aacute;st&iacute; druh&eacute;ho, tak je tento vztah exponenci&aacute;ln&iacute;. V logaritmick&eacute;m prostoru m&aacute; pak matematick&aacute; funkce IAR podobu př&iacute;mky.</p>
<p>Při nepravideln&eacute;m překryvu ploch nejprve roste strměji a pot&eacute; konverguje s př&iacute;mkou, ale t&iacute;mto př&iacute;kladem se pro dal&scaron;&iacute; &uacute;čely nebudeme zab&yacute;vat.</p>
<h3>Vysvětlen&iacute; zplo&scaron;ťov&aacute;n&iacute; SAR pod IAR na mal&yacute;ch ploch&aacute;ch</h3>
<p>SAR křivka vykreslen&aacute; do grafu s logaritmick&yacute;mi osami mus&iacute; b&yacute;t vždy pod IAR. Počet druhů na dan&eacute; plo&scaron;e totiž nemůže b&yacute;t vy&scaron;&scaron;&iacute; než počet jedinců.</p>
<p>Nejprve maj&iacute; IAR a SAR podobn&yacute; průběh, a to proto, že na &uacute;plně nejmen&scaron;&iacute;ch ploch&aacute;ch je každ&yacute; druh reprezentov&aacute;n pr&aacute;vě jedn&iacute;m či jen velmi mal&yacute;m počtem jedinců.</p>
<p>Č&iacute;m vět&scaron;&iacute; je plocha, t&iacute;m v&iacute;ce se SAR pod IAR v logaritmick&eacute;m prostoru zplo&scaron;ťuje. N&aacute;růst počtu druhů nemůže b&yacute;t totiž stejn&yacute; jako n&aacute;růst počtu jedinců. Každ&yacute; druh je reprezentov&aacute;n v&iacute;ce jedinci.</p>
    <hr />
    <ImgCon><Biogeo /><div>
    <h2>Studijn&iacute; materi&aacute;ly Biogeografie</h2>
    <p>T&eacute;ma <strong>SAR: Vztah diverzity a plochy</strong> je souč&aacute;st&iacute; <Link to="/biogeografie/">studijn&iacute;ch materi&aacute;lů Biogeografie</Link>. Tento předmět byl vyučov&aacute;n na <a href="http://geogr.muni.cz">Geografick&eacute;m &uacute;stavu</a> <a href="http://muni.cz">Masarykovy univerzity</a>. Přejděte na rozcestn&iacute;k t&eacute;mat (1.&nbsp;tlač&iacute;tko), nebo si přečtěte předch&aacute;zej&iacute;c&iacute; (2.&nbsp;tlač&iacute;tko) či n&aacute;sleduj&iacute;c&iacute; (3. tlač&iacute;tko) t&eacute;ma.</p>
    <BtnWrap>
    <Link to="/biogeografie/"><button>Rozcestník biogeografie</button></Link>
    <Link to="/biogeografie/biodiverzita/"><button className="inv">&larr; Biodiverzita</button></Link>
    <Link to="/biogeografie/biodiverzita/globalni-druhove-bohatstvi/"><button className="inv">Globální druhová bohatost &rarr;</button></Link>
    </BtnWrap>
    </div></ImgCon><hr />
  </Layout>
)
